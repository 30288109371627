import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Gallery from '../../components/gallery.jsx';
import Embed from '../../components/embed.jsx';
export const _frontmatter = {
  "key": 8,
  "title": "Rebranding",
  "client": "Bytecode",
  "type": "Strategy, Branding, UX/UI-Design, Graphic Design, Front-end Development",
  "date": "2018",
  "description": "Bytecode is a young, driven digital agency that wants to make you as effective as possible at what you do. That's why they strive to solve the puzzle of reseaching and creating digital solutions that fit like a glove. They asked to create and professionalise their visual language and to help implementing it on assets like their new website.",
  "image": "../../static/img/work/bytecode/team.png",
  "alt": "Bytecode team",
  "draft": true,
  "rating": 4,
  "website": "https://bytecode.nl",
  "repo": "https://github.com/BytecodeAgency/Bytecode-Website"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Gallery width={50} mdxType="Gallery">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "2000px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/e9ed5097ceccf5a11b80bc5ecc1403ea/d40c8/logo.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "77.85%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAACnxAAAp8QFUtQ3EAAABo0lEQVQ4y2NgGBJASkqKYgwzB86hFEhKSiI4ctVRDEpTchiEoh3BfE5uLgYDAwMGbm5uBjExMbClIA0SEhIMCgoKDKysrAxCQkIMioqKYHEUF4KACBCzA7EEko2MQMDExMSIDcDk+fn5Gd+/fw+2CMVAmQgnsCIZOyOgGUxChLwHdCEjjM3FxcUIcjWKgQprqsXUzk1yE4pzBvm5hYGJ0QdIKwFxCBBbAJ2jx8DEpA2kDYFyQJohHcg2A1ouANLPx8fHiGKgcKwzq9aTRfo8TvppQG4zUFMDkM4C4mIgrgXiMqjhlUDcBsTtQNwJMgOknw3qYrCBinML4V7h1JAFKZAB2q4GDCIRRhZmUVAE8vmYKfMHW5vweZnq8AdaagnGu5gBxfSFTDWYVUFhr6uKiGlxIEOlLo5BPNuXkdgk8v//fzCt5evIIMzEwSCtII+UDmVlGMS5+RmUvWwY+DXlGVmAzuPh5WXg5ORkZGNhZWTn5cZpkSAXL4Ms0DAxASHUSCEE8oAuMgBiDyA2BGKt/48ZQqCuRM5tBLMdLNGSlPUGPQAA6dtImxK5HYoAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/e9ed5097ceccf5a11b80bc5ecc1403ea/3cea3/logo.webp 1000w", "/static/e9ed5097ceccf5a11b80bc5ecc1403ea/b7abf/logo.webp 2000w"],
                "sizes": "(max-width: 2000px) 100vw, 2000px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/e9ed5097ceccf5a11b80bc5ecc1403ea/e11df/logo.png 1000w", "/static/e9ed5097ceccf5a11b80bc5ecc1403ea/d40c8/logo.png 2000w"],
                "sizes": "(max-width: 2000px) 100vw, 2000px",
                "type": "image/png"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/e9ed5097ceccf5a11b80bc5ecc1403ea/d40c8/logo.png",
                "alt": "Bytecode Logo",
                "title": "Bytecode Logo",
                "loading": "lazy"
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "2000px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/aa6c1937f2599901536079ba9c2591f1/d40c8/icon.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "67.80000000000001%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAPYPAAD2DwEqTLg/AAACFUlEQVQ4y4WTX2hOYRzHz3mPbWSTC7XSlnfP2S4kYRdboSVS71Yru5ALk5s1i71hxeqddzbenbNSw4UiJUpSaHek7Zwk3EiRC0Vi444LlLjC5zn7nsXb8NSnX8/vPL/v8/vzHMePw8PQZqJgC7bVj4NN2ENQha/f3C21Oyy3clEj5hE8kLXch2uw00kXgf0EhtgLMA69Jg6G8J2DHgQndHQX/PwHxTnBKNyDyH4rBGcgjy+PvQrdDXdODCYHM25JgU/AQBO0wEP538Bim2ERGqEA22wLTBw2Y9eTYXHVraGViaDr3lTgRefPlZf/NVSnZffAXgSW64K19LLNTI0l/VuywV+KearAvIQqZM/KP5mKdSRlxmGJUidMFO4mwxHET5qp0kF7xluxLIv5psCchCphh3xfYU0qmNekRxA6rX2XnTD969SEcwr8Ae/gBbyX7zHU65KMFfQpbxhbB+0aUA0MZiePtWggRxT8BZ7Bc3gp3yfYJ0HXWWiRaY4MR+uvDNTq2CUF3yg7ekB+245sWrILGYv2ZBweNdPBgH9vvEsZpk9jWEJVsk2/CTbPZRMFViQR1n4jGZ5iwoXVs5frcNXARwV2lmXYJ//b+SczX2YUphd40Ntwe3RzUm2F16qg79ABtXrY3fBB3wqS8cp7t1BL+/7zy53/2yDSnjpmesyr3roufbjH4bP+hBmYhVdwHbaX6/wC5TPujRXfuIIAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/aa6c1937f2599901536079ba9c2591f1/3cea3/icon.webp 1000w", "/static/aa6c1937f2599901536079ba9c2591f1/b7abf/icon.webp 2000w"],
                "sizes": "(max-width: 2000px) 100vw, 2000px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/aa6c1937f2599901536079ba9c2591f1/e11df/icon.png 1000w", "/static/aa6c1937f2599901536079ba9c2591f1/d40c8/icon.png 2000w"],
                "sizes": "(max-width: 2000px) 100vw, 2000px",
                "type": "image/png"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/aa6c1937f2599901536079ba9c2591f1/d40c8/icon.png",
                "alt": "Bytecode Icon",
                "title": "Bytecode Icon",
                "loading": "lazy"
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "4000px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/33c5ca734ffa73c513c0753776587ebb/4b849/text.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "94.64%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAH/cAAB/3AHnmc9MAAABzElEQVQ4y+2RSy8DURTHZ/pEPTIeZR6lHR1arQ7xSFh41aKIoCUS0cQHsBCPxA4holY+gZX4BCIkNiKxsLCysWHje4z/qTu0nRDF0j/59X/Oufece3uH0+6O+puvM8ngzeFc4GxrUL3cHQ6c7wypF7tjhmFwJFtlqZ2MwXNfyd0iK0J6RCgf0b2WRRuv4tfLFakNsASWwRjoA+NgFGTAApgHA2AR+Fjfpzddx9Is/BjsgxVwCg7ANtgBm2APnACNmpxOp02SpOwA000JgN6oFoigEcjsJgFQDzygA6yy2rssQ0OhkEtRlBJ4SSQSccfjcXs4HC7VdZ1iRzQadWmaRrlDEIRqv99fJopiFQZ4zCHIP05AooJeEAM9QGdxF2gHYZa3YgCtReDd8A7gRcznDUWhEfQC2tgJaKMKj+UMiLLDaHgby+mQIGJ73l9GsYkaZUnywb3IFXgNvA5ITHSTbJ057aVFl+WjoKm2pqFB5txuB/cDWT6KsbbmNNLpupdUSn6YmhKvEonsqXFN4+l9GJzpuMB73Xw3Vn8beDsx4bqfnKx4nJ4uf04mPU8zM9k3QZx3E7OBPDf+lgqHFaUnND8X8Ouh//oT8QX8WK+NPF+SRuOJBwAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/33c5ca734ffa73c513c0753776587ebb/3cea3/text.webp 1000w", "/static/33c5ca734ffa73c513c0753776587ebb/b7abf/text.webp 2000w", "/static/33c5ca734ffa73c513c0753776587ebb/cc8bc/text.webp 4000w", "/static/33c5ca734ffa73c513c0753776587ebb/2e009/text.webp 5000w"],
                "sizes": "(max-width: 4000px) 100vw, 4000px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/33c5ca734ffa73c513c0753776587ebb/e11df/text.png 1000w", "/static/33c5ca734ffa73c513c0753776587ebb/d40c8/text.png 2000w", "/static/33c5ca734ffa73c513c0753776587ebb/9c46e/text.png 4000w", "/static/33c5ca734ffa73c513c0753776587ebb/4b849/text.png 5000w"],
                "sizes": "(max-width: 4000px) 100vw, 4000px",
                "type": "image/png"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/33c5ca734ffa73c513c0753776587ebb/9c46e/text.png",
                "alt": "text",
                "title": "text",
                "loading": "lazy"
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p>{`![](../../static/img/work/bytecode/color_swk aps.png)`}</p>
    </Gallery>
    <Gallery width={50} mdxType="Gallery">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "4000px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/5eba84341a02627bf453b9c5346f419b/4b849/design-philosophy.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "77.52%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAADq1AAA6tQEcP1TFAAACHUlEQVQ4y52UPYsTURSGp7NQ7FcEwc4/YKloYa3YqbB/QEVBC0srLdYfIJYWNmncTlYUQkBlFxc3G0RisslM4mQ+Mh/5/pq83vcmd5i9MaCe4eTemXPmue85c28M13VBdxwnHbNzFf9bN3RA1l3n36GGoylst9vwXO+/lRru8kXP89BqtfDt4ACVXxY8AdahjP+pNasKRcC2bZimib3dPex8/IDSj+8LtWIhu2VLWBAEcqSvgy6AAmbXaqgJtywLR9Uq8vk8CoUCTMtEN+4gDiMclkqIO2Iex2vVGmmPREIUhjK52+2i3+/jZ6WCOAhhhz4ePHmMc2fO4v7de/i6v484iqTiVYXiAUvr9XoYDocSxPlAzGlfgjouvH+O028e4vzTTZw4dRI3r9/A2+1tFItF+e4xhR1RwmAwSH00GmEymWA+n0P8wI0CvC5/wtXPL7Gxu4WNzWu4cukybt25jVwuh0gozfbUIEQpo/NemYTSZoDT9vHs8B0u7mzh0asXMCtHMhSKNq30kH0jlM6++L4vlSqbzRNBF5PRFF4/Tp8nSSLH8XgsS6fS9KQQwo9BEEduF64+nU4XasWVLBUns0TCWEFahTBWZ2TPsvpALJ1gKmeMfVZqkiVEhzGnXq9nto12BKlObwMX0o0Lc++Wy2U0Go3jCnW12f5yAW78ZrMpIVTMHIKojLlsk6GfxXVgNVIFwVVxmujqf0Dl/gbQA5t2I7S1IgAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/5eba84341a02627bf453b9c5346f419b/3cea3/design-philosophy.webp 1000w", "/static/5eba84341a02627bf453b9c5346f419b/b7abf/design-philosophy.webp 2000w", "/static/5eba84341a02627bf453b9c5346f419b/cc8bc/design-philosophy.webp 4000w", "/static/5eba84341a02627bf453b9c5346f419b/2e009/design-philosophy.webp 5000w"],
                "sizes": "(max-width: 4000px) 100vw, 4000px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/5eba84341a02627bf453b9c5346f419b/e11df/design-philosophy.png 1000w", "/static/5eba84341a02627bf453b9c5346f419b/d40c8/design-philosophy.png 2000w", "/static/5eba84341a02627bf453b9c5346f419b/9c46e/design-philosophy.png 4000w", "/static/5eba84341a02627bf453b9c5346f419b/4b849/design-philosophy.png 5000w"],
                "sizes": "(max-width: 4000px) 100vw, 4000px",
                "type": "image/png"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/5eba84341a02627bf453b9c5346f419b/9c46e/design-philosophy.png",
                "alt": "design philosophy",
                "title": "design philosophy",
                "loading": "lazy"
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1000px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/b7c494c8fb7e0021719a2b134ef0f0e1/e11df/team.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAADRUlEQVQ4y32VW08TURDHSwuUllJ723u72/uFtkCpXApUC71IISQmkhAJmCDhEoKSGEwU44MRvwAPPhn9IpLwxjOf6O+ZA1u3BX3YzDlnOr/5z8zZrU1VVCiqArLmY93T+p9+9f5jM51kZUug3AOy7nsTWuE262FCDrNAFqzdriOqBpH5ylIMJTl+C1WUhxP0AsPMNoUMpoNRBIQQxqUoZoMxBpRRFVOYfxSFJinIKgYk9vukqkNjAJ5A7VFI2XSm5rk/i83cPBZm51AKGWikS4gzpdVAHMvVRYzGU8h5ZWiygrRPRlgmlVS6ch8Y1SJoDurYWt/A549nWGCKPnw6w/uXu2gEUvjy7SvaKytYrtUxlypiZamJRCwOSZI4yFR6WzLrWTgo4fVcG5tbWzje3UdajuD47RucHBxBEUTU63Wk0mm82t7GxGgBP379RKPRgCiKHXUEtdFCZot4xMDjXBFPF2v4fXkJm8OOUCgEn98H9/AwVxLwB1AqleDo78f42Biy2SxisRjC4bBFIQFlmTs8Hg9WV1dxdXUFe5+dw1wuF9xuNwYGBuDz+RCJRGC322Gz2fgzNTWFXC7XUdoB0qHD7sD6+jqur68xzFSNjIxwS4mGhoY6EIfDwW25XMbBwQE0TetWKAgCpqenoTLH2toabm5ueIkEIkVUEsH7Wamkjh5Su7Ozg2g0yuO7ekhyC/k82k+WoEd07O3vwe/388BkMsnV054gfX19XF0mk8HFxQVmZmZ4r0nl3ZSVjlwpJGBwcJAHkCUgZQ8Gg7x0gQX235VLAzk/P0er1eLJOkDFMnItrPFSSQmV6PV64WS9czqd8Ix4kB0vYMgzzAdVrVZxenrKgYFAwKLwDka90nWdOwhKWal3Lgb0MHjQ68PGZA2tcoUnrNVqODw87AL+vYdsyoZh8AZTz9LsAudZT5vNJkRWshYzsFas4J0yh+8vjvBspop0NoNisYhKpdJ5W7oUUgZSSKoITgro7Tg5OUG+WMBkehTt+ASWjAJSRgy6oXMA9fLetTGh5EwkEhxKqmkg+UKBtyMoCpDYmShL3JoAxTLUTsnmhkAmzDynK8UrUB/+Qlvjb6fck6H3+9YV8J+/ADPuD1ukLn5EcUvsAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/b7c494c8fb7e0021719a2b134ef0f0e1/3cea3/team.webp 1000w"],
                "sizes": "(max-width: 1000px) 100vw, 1000px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/b7c494c8fb7e0021719a2b134ef0f0e1/e11df/team.png 1000w"],
                "sizes": "(max-width: 1000px) 100vw, 1000px",
                "type": "image/png"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/b7c494c8fb7e0021719a2b134ef0f0e1/e11df/team.png",
                "alt": "team",
                "title": "team",
                "loading": "lazy"
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1972px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/ea73f077b4b88c942e413067d1412f13/0bfbb/empty.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "170.18255578093306%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAiCAYAAABfqvm9AAAACXBIWXMAAAsSAAALEgHS3X78AAADGUlEQVRIx+1WW08TQRjlwUQTIkLbbXd2ZndblEtxaREoBSz0Ak2EZgsW5Ga8BDVqookPxhATo4nG+OAP8Gf4D48z39C1F5ZS9dGHL7M7nZ455ztnph2wLAv/sgb+A55bjDH9zGUJrqt1vh9A+hI/fR6JgQ0Og12LBmDNceAijDQYB4vFwYwExJ1FOG/3YO+v0SY9GbZKY6apx6gBPj2O5PdncE8O4LzZwejPD3JugjYJBQxYJUwCsZKOBp28juSP1xBbK1JqBOalQTgvfAi/QNKVgnCGpgS9noSozBMT9e6+24d9rwx2eUhvMhyFfbwJ++4qbdAFqJgFvVKM0jcginOwG0UCdz8/guXYv52V7JPfnoDfSlNv2yS3Np8arxZIiTwzAVFb1oBfHmt3hyJgV0eIuXtySEyb7LoYqlHtaN+vwn1/BLG+IGXnwK4Mwf34AM7LLYjtVbhfj+F+eniqhp0dbJIpeyM2l6h39uE6eM4DX8xAbCyRGc5zH86rbWoFZdBkZwe7LSYysHw5C3unRHGwD9Y0aL1AUunzmTS4N05JCD16gSERg4zgS1nw/DSBWeOjOh5yjb1X0XW03sbs7BxKB1WTnac1MkW927slkqvYi/I8sVdrmnOdoN3BjidInlidJXN4YQbWqEtmqI1EUc7vV6SCjE5D2NELoiP7xmenpDnLepRSrZQrZZaJtTKMZyeD7AX5De9hjNJPfSvNgUumygzlNG2gZEv3mREPbp+ekimLu2XNRhkj38Vajm4ZAlzwArnnArZeDNQbeTIIUEZE9VGdHGVKZ/bOZ9hxYVoph4AoAYY+ll1rev0EtC5sC6+SyPmfM1QlhEA8Hocpj2az+mbYrLGxMfi+j0qlgkajgWq1Cs/zQtmFAioWqVQKpVIJxWKRABVYoVBAvV5HOp0m1heX3AyrLCMWQzQaRSQSoWoC9d1DzizEOEPF38CWX0epXMZmrYZ8Ph/KrmcPE1K6l80gN5/DzNwsFlduI31zCmYi0R9g6/mMGwZiUrYhRyW9VXLfLgfyT7PHe2Twwn9FOgH+GrCf+gVqSKDiXGhwJQAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/ea73f077b4b88c942e413067d1412f13/3cea3/empty.webp 1000w", "/static/ea73f077b4b88c942e413067d1412f13/b2001/empty.webp 1972w"],
                "sizes": "(max-width: 1972px) 100vw, 1972px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/ea73f077b4b88c942e413067d1412f13/e11df/empty.png 1000w", "/static/ea73f077b4b88c942e413067d1412f13/0bfbb/empty.png 1972w"],
                "sizes": "(max-width: 1972px) 100vw, 1972px",
                "type": "image/png"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/ea73f077b4b88c942e413067d1412f13/0bfbb/empty.png",
                "alt": "empty",
                "title": "empty",
                "loading": "lazy"
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1873px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/85017d495202e6237bab66ae1b52c0de/f74d3/kerstkaart.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "138.76134543513078%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAgABBf/EABYBAQEBAAAAAAAAAAAAAAAAAAECAP/aAAwDAQACEAMQAAAB50gWo2FmwiFUf//EABsQAAIDAAMAAAAAAAAAAAAAAAECABESISIz/9oACAEBAAEFAg1LkQqI3YsBWGmKhBwzXGbhvKf/xAAWEQEBAQAAAAAAAAAAAAAAAAAQAiH/2gAIAQMBAT8BKw//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAfEAACAgEEAwAAAAAAAAAAAAAAAQIxERBBYXESIoH/2gAIAQEABj8CIcl47IJYrYTWSrL9keblwLGyO7IfdP/EABsQAQADAQEBAQAAAAAAAAAAAAEAESExQVFx/9oACAEBAAE/ITo29/GOQsTbcx6zyCUZm4AxrNjWLwsuYNe1I4mbcXs+WwjPAPY9nFRqotts/9oADAMBAAIAAwAAABBgxsD/xAAYEQEBAQEBAAAAAAAAAAAAAAABABExIf/aAAgBAwEBPxB1NeEpvICwn2//xAAYEQACAwAAAAAAAAAAAAAAAAAAIQEQgf/aAAgBAgEBPxA0huv/xAAgEAEAAgIBBAMAAAAAAAAAAAABABEhMUFRcZHBYYHR/9oACAEBAAE/EKVxAodnLOZwqHahR9QuS3Ird7loVoNKq31alc5ahpxyfEtODIAUuvUbcdBvB36x8DSdtL/PMAEIF23blXyy0hzAzCAoWrWVsPUbmO0//9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/85017d495202e6237bab66ae1b52c0de/3cea3/kerstkaart.webp 1000w", "/static/85017d495202e6237bab66ae1b52c0de/6e89f/kerstkaart.webp 1873w"],
                "sizes": "(max-width: 1873px) 100vw, 1873px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/85017d495202e6237bab66ae1b52c0de/971c4/kerstkaart.jpg 1000w", "/static/85017d495202e6237bab66ae1b52c0de/f74d3/kerstkaart.jpg 1873w"],
                "sizes": "(max-width: 1873px) 100vw, 1873px",
                "type": "image/jpeg"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/85017d495202e6237bab66ae1b52c0de/f74d3/kerstkaart.jpg",
                "alt": "kerstkaart",
                "title": "kerstkaart",
                "loading": "lazy"
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>
    </Gallery>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "4000px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a20737bc179e1910f55b2e3e19a6df62/35c5b/site.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.66197183098592%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABeElEQVQoz52RSW7CQBBFfQEEthRYY3cbG4eAEVNkCDkAtyC3YBQQxbtwK6RwAfawYMko2JCf7gITBGKRWPoqtbvqVdVvZbFYYL1e4/twwEHoP5+sWy6XkCxlu91iMpngw3/H53CIZrOJRqOBVquFbrdL6nQ66PV6GAwG6Pf7dJZqt9uU5/s+5vM5VqsVlP1+j9FohGgshodoFJqmQVVVUiQSQTgcpnj5T9VUypMKhULgnGM6nWKz2UDZ7XYYj8dwHIcuzEQC3BTRNGFZFizbhn1HyWSSckulEmaz2REoJ5RACXBSj3iueCi/viCXz1NRQjRgjIFxKU5NzxKweDwO13V/JwyAsqhYKOKtXketVkO1WoXneZRssCOMmUcQO4mLISQwm83eAmlCsXa5UkEmk0E6nSZY6il1BlyLgPo9oFjNFp4VxGVOyBWS0MDbPwOlV9ww4MiHEVHXdRgiSisCv67B5r2Vv05AAgTdbx7APAEZeSlj8CiXwB8PmLW9D7ejXQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/a20737bc179e1910f55b2e3e19a6df62/3cea3/site.webp 1000w", "/static/a20737bc179e1910f55b2e3e19a6df62/b7abf/site.webp 2000w", "/static/a20737bc179e1910f55b2e3e19a6df62/cc8bc/site.webp 4000w", "/static/a20737bc179e1910f55b2e3e19a6df62/a2786/site.webp 5325w"],
              "sizes": "(max-width: 4000px) 100vw, 4000px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/a20737bc179e1910f55b2e3e19a6df62/e11df/site.png 1000w", "/static/a20737bc179e1910f55b2e3e19a6df62/d40c8/site.png 2000w", "/static/a20737bc179e1910f55b2e3e19a6df62/9c46e/site.png 4000w", "/static/a20737bc179e1910f55b2e3e19a6df62/35c5b/site.png 5325w"],
              "sizes": "(max-width: 4000px) 100vw, 4000px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/a20737bc179e1910f55b2e3e19a6df62/9c46e/site.png",
              "alt": "site",
              "title": "site",
              "loading": "lazy"
            }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>
    <Gallery fluid width={80} mdxType="Gallery">
    </Gallery>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "4000px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/20a1c9d2a68c0b12b5a2b37509d2a523/635c7/cards.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.874549387166546%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAACXElEQVQoz22Sy0uUURiH5x8oUjBrUrx0sSYSTch04aLIKMhsEGfSvuYmbcKIqJW0qaSyRcuIKEjL26hkaosMw41WC03n4iV1bFLGyRl1Zhy1Jn0639coUh14ec85nPc57+WnMjisWL62I/uT969ytuYOlsl2TGOtGOyNGJxNGIeaFa/so379/m9TmcdfU1B9m/TjR9mxdQupiWpyzefQdz3G4u7AONKigBVQNMg81LLxyT/AbOkMyTvj0RxMQ1usZd/uZHbFbCNNs5djNy8hfa6j7NubDZAkKimy1SI5rf8Fq1Lj4kgSwP0H9qAtLMBsukhuzhFSEtSkxG9Hk5PJqYc3MNoaKbLXUT7WwfPZAS6PtilgGWKSYc4/YJXu5T2y8/OUYHVcLBmZhyi5oEenKyJJHU+K+DBXdxrpQzVPvH0MuEbp7/3EuM/DU3GWM9Xb65VsZbDK5GrDbLNSWHWdjKx0EmJjSBQgTXISOSfykF5U8cDTQ19gGtYg4J/HOTyEb8bLvHcWR2iGSnc3xSL7844GVAa7VWm2WUza1FtDfrlEVvZhtHevKWVWuLtYWA7zYy5IaCnMorBQMIjP72dqegr32Dh+se9emKRi/K0AKvWLCQqw8csrLEIuZf0NWFxCSsPNSLYGrjha6fQME4lEWFlZ4vucj0AwwOraGsHwIovzC8jrvXcUlXGTpgyOJkWPMkjx0ftS0afiwVpujXTiDHhE6atEfv1UWiCviZCPypF3lAzWCeBm0a5LYN2iZ1NUMnrRo9LBeh5N9DCzHGBuJcwz10ekgXp0YjDyu9925DD2hSilagAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/20a1c9d2a68c0b12b5a2b37509d2a523/3cea3/cards.webp 1000w", "/static/20a1c9d2a68c0b12b5a2b37509d2a523/b7abf/cards.webp 2000w", "/static/20a1c9d2a68c0b12b5a2b37509d2a523/cc8bc/cards.webp 4000w", "/static/20a1c9d2a68c0b12b5a2b37509d2a523/d6a73/cards.webp 5548w"],
              "sizes": "(max-width: 4000px) 100vw, 4000px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/20a1c9d2a68c0b12b5a2b37509d2a523/e11df/cards.png 1000w", "/static/20a1c9d2a68c0b12b5a2b37509d2a523/d40c8/cards.png 2000w", "/static/20a1c9d2a68c0b12b5a2b37509d2a523/9c46e/cards.png 4000w", "/static/20a1c9d2a68c0b12b5a2b37509d2a523/635c7/cards.png 5548w"],
              "sizes": "(max-width: 4000px) 100vw, 4000px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/20a1c9d2a68c0b12b5a2b37509d2a523/9c46e/cards.png",
              "alt": "cards",
              "title": "cards",
              "loading": "lazy"
            }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      